import React from 'react';
import ScrollUpButton from "react-scroll-up-button";
import CopyToClipboard from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import LoyaltySharpIcon from '@material-ui/icons/LoyaltySharp';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import GifTwoToneIcon from '@material-ui/icons/GifTwoTone';
import GifIcon from '@material-ui/icons/Gif';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Badge from '@material-ui/core/Badge';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './App.css';
import queryString from 'query-string';

const localKey = 'chainlinkmemeFavs';

const applyUpdateResult = (result, page) => (prevState) => ({
  memes: [...prevState.memes, ...result.memes],
  totalMemesCount: result.meta.total_memes_count,
  tagNames: result.meta.tag_names,
  page: page,
  isLoading: false,
  serverError: false,
});

const applySetResult = (result, page) => (prevState) => ({
  memes: result.memes,
  totalMemesCount: result.meta.total_memes_count,
  tagNames: result.meta.tag_names,
  page: page,
  isLoading: false,
  serverError: false,
});

const getMemes = (value, page, gif, favs, favMemeIds) =>
  (value === null || value === '')
    ? `${process.env.REACT_APP_API_HOST}/memes?page=${page}&animated=${gif}&favs=${favs}&meme_ids=${favMemeIds}}`
    : `${process.env.REACT_APP_API_HOST}/memes/search?query=${value}&page=${page}&animated=${gif}`;

const getMemeUrl = (meme) =>
  meme === undefined
    ? ''
    : `https://chainlink-meme.s3.us-east-2.amazonaws.com/${meme.filename}`

const getGridListCols = (width) => {
  if (width > 1096) {
    return 3;
  }

  if (width > 768) {
    return 2;
  }

  return 1;
}

const StyledBadge = withStyles(theme => ({
  badge: {
    right: '20%',
    top: '-20%',
    transform: 'scale(0.55) translate(33%, 33%)',
  },
}))(Badge);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memes: [],
      totalMemesCount: 0,
      tagNames: [],
      page: null,
      searchText: null,
      isLoading: false,
      serverError: false,
      gifOnly: false,
      favsOnly: false,
      favMemeIds: [],
      width: 0,
      height: 0,
      modalOpened: false,
      openedMemeIndex: null,
      scroll: 0,
      copied: false,
    };
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener("scroll", this.progressBar);
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const queryParams = queryString.parse(window.location.search);
    const tags = queryParams.tags ? queryParams.tags : queryParams.t ? queryParams.t : null;
    if (tags) {
      this.setState({ searchText: tags });
    }
    this.fetchMemes(tags, 1, false, false, '');
    if (localStorage.getItem(localKey)) {
      const favMemeIds = JSON.parse(localStorage.getItem(localKey));
      this.setState({ favMemeIds: favMemeIds });
    }
    window.addEventListener("scroll", this.progressBar);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  progressBar = () => {
    const scrollTotal = document.documentElement.scrollTop;
    const heightWin = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scroll = `${scrollTotal / heightWin * 100}%`;

    this.setState({ scroll: scroll });
  };

  onInitialSearch = (e) => {
    e.preventDefault();
    const searchText = (this.state.searchText !== null && this.state.searchText !== "")
      ? this.state.searchText.split(/,|, | /).filter(function(el) { return el !== ""; })
      : null;
    this.setState({ favsOnly: false });
    this.fetchMemes(searchText, 1, this.state.gifOnly, this.state.favsOnly, this.state.favMemeIds);
  }

  onPaginatedSearch = (e) => {
    const searchText = this.state.searchText !== null
      ? this.state.searchText.split(/,|, | /).filter(function(el) { return el !== ""; })
      : null;
    this.setState({ favsOnly: false });
    this.fetchMemes(searchText, this.state.page + 1, this.state.gifOnly, this.state.favsOnly, this.state.favMemeIds);
  }

  fetchMemes = (value, page, gif, favs, favMemeIds) => {
    this.setState({ isLoading: true, gifOnly: gif });
    fetch(getMemes(value, page, gif, favs, favMemeIds))
      .then(response => response.json())
      .then(result => this.onSetResult(result, page))
      .catch((error) => this.onServerError(error));
  }

  handleTextChange = (e) => {
    this.setState({ searchText: e.target.value });
  }

  handleAutoComplete = (e) => {
    let searchText = e.target.textContent === '' ? e.target.value : e.target.textContent;
    searchText = searchText === undefined ? null : searchText
    this.setState({ searchText: searchText });
    this.fetchMemes(searchText, 1, this.state.gifOnly, this.state.favsOnly, this.state.favMemeIds);
  }

  handleGifCheckbox = (e) => {
    this.setState({ gifOnly: e.currentTarget.checked });
    this.fetchMemes(this.state.searchText, 1, e.currentTarget.checked, this.state.favsOnly, this.state.favMemeIds);
  }

  handleFavoriteCheckbox = (e) => {
    this.setState({ gifOnly: false, favsOnly: e.currentTarget.checked });
    this.fetchMemes(null, 1, false, e.currentTarget.checked, this.state.favMemeIds);
  }

  handleTagIconClick = (text) => {
    this.setState({ searchText: text });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.fetchMemes(text, 1, this.state.gifOnly, this.state.favsOnly, this.state.favMemeIds);
  }

  handleLikeIconClick = (that, id) => {
    const favMemeIds = localStorage.getItem(localKey);
    if (favMemeIds === null) {
      that.setState({ favMemeIds: [id] });
      localStorage.setItem(localKey, JSON.stringify([id]));
    } else if (!favMemeIds.includes(id)) {
      let storedFavs = JSON.parse(favMemeIds);
      storedFavs.push(id);
      that.setState({ favMemeIds: storedFavs });
      localStorage.setItem(localKey, JSON.stringify(storedFavs));
    } else {
      return;
    }

    that.state.memes.forEach((m, i) => {
      if (m.id === id) {
        that.setState(prevState => {
          const newMemes = [...prevState.memes];
          newMemes[i].likes = newMemes[i].likes + 1;
          return { memes: newMemes };
        });
      }
    });

    fetch(`${process.env.REACT_APP_API_HOST}/memes/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        likes: '1',
      })
    });
  }

  handleCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  handleImgClick = (index) => {
    this.setState({ modalOpened: true, openedMemeIndex: index });
  }

  handleModalClose = () => {
    this.setState({ modalOpened: false, openedMemeIndex: null });
  }

  onSetResult = (result, page) =>
    page === 1
      ? this.setState(applySetResult(result, page))
      : this.setState(applyUpdateResult(result, page));

  onServerError = (error) => {
    this.setState({ isLoading: false, serverError: true });
    console.log("server error");
  }

  render() {
    return (
      <div className="page">
        <div className="progress-bar">
          <div className="progress" style={{ width: this.state.scroll }} />
        </div>
        <div className={"interactions search"}>
          <form type="submit" onSubmit={this.onInitialSearch}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
              <Grid item lg={5} md={4} sm={6} xs={12}>
                <Autocomplete
                  freeSolo
                  size="small"
                  options={this.state.tagNames}
                  onChange={this.handleAutoComplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="search-box"
                      placeholder="1keoy"
                      autoFocus={true}
                      label={`${this.state.totalMemesCount} memes`}
                      variant="outlined"
                      size="small"
                      onChange={this.handleTextChange}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Button type="submit" variant="outlined" color="primary" style={{ padding: '7px 15px' }}>
                  𝚍𝚒𝚜𝚌⬡𝚟𝚎𝚛
                </Button>
                <Checkbox
                  checked={this.state.gifOnly}
                  color="primary"
                  icon={<GifTwoToneIcon />}
                  checkedIcon={<GifIcon />}
                  onChange={this.handleGifCheckbox}
                />
                <Checkbox
                  checked={this.state.favsOnly}
                  color="primary"
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  onChange={this.handleFavoriteCheckbox}
                />
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="meme-list">
          <GridList className="grid-list" cols={getGridListCols(this.state.width)} cellHeight={333}>
            {this.state.memes.map((tile, index) => (
              <GridListTile
                key={tile.id}
                cols={tile.width > 777 ? 2 : 1}
                rows={tile.height > 555 ? 2 : 1}
              >
                <img
                  src={getMemeUrl(tile)} alt={`chainlink-meme-${tile.tags.map(tag => tag.name).join('-')}`}
                  style={{ objectFit: 'fill' }}
                  onClick={() => this.handleImgClick(index)}
                />
                <GridListTileBar
                  title={
                    <div>
                      <StyledBadge
                        badgeContent={tile.likes}
                        max={77}
                        showZero
                        color="primary"
                        overlap="circle"
                      >
                        <IconButton
                          style={{ color: 'white', padding: '0 10px 0 0' }}
                          onClick={() => this.handleLikeIconClick(this, tile.id)}
                        >
                          {this.state.favMemeIds && this.state.favMemeIds.includes(tile.id) && <FavoriteIcon />}
                          {this.state.favMemeIds && !this.state.favMemeIds.includes(tile.id) && <FavoriteBorderIcon />}
                          {!this.state.favMemeIds && <FavoriteBorderIcon />}
                        </IconButton>
                      </StyledBadge>
                      {tile.tags.map(tag => tag.name).join(' ⬡ ')}
                    </div>
                  }
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${tile.tags.map(tag => tag.name).join(' ')}`}
                      style={{ color: 'white' }}
                      onClick={() => this.handleTagIconClick(tile.tags.map(tag => tag.name).join(','))}
                    >
                      <LoyaltySharpIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
        <Modal
          aria-labelledby="meme-modal"
          aria-describedby="meme-full-image"
          open={this.state.modalOpened}
          onClose={this.handleModalClose}
        >
          <div className="modal">
            <CopyToClipboard key={this.state.memes[this.state.openedMemeIndex]} text={getMemeUrl(this.state.memes[this.state.openedMemeIndex])} onCopy={this.handleCopy}>
              <img src={getMemeUrl(this.state.memes[this.state.openedMemeIndex])} alt="chainlink-meme" style={{ cursor: 'pointer' }} />
            </CopyToClipboard>
          </div>
        </Modal>
        {this.state.copied && (
          <div style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'green',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1000,
          }}>
            meme link copied
          </div>
        )}
        <div className="interactions bottom-btn">
          {
            (this.state.memes.length > 0 && this.state.page !== null && !this.state.isLoading && !this.state.serverError && this.state.memes.length % 21 === 0) &&
            <Button onClick={this.onPaginatedSearch} variant="outlined" color="primary">
              𝚖⬡𝚛𝚎 𝚖𝚎𝚖𝚎
            </Button>
          }
          {!this.state.isLoading && this.state.serverError && <Button variant="outlined" disabled>Server Error</Button>}
          {this.state.isLoading && !this.state.serverError && <CircularProgress />}
          {!this.state.isLoading && !this.state.serverError && this.state.memes.length % 21 !== 0 && !this.state.favsOnly && <Button variant="outlined" disabled>⬡𝚞𝚝 ⬡𝚏 𝚖𝚎𝚖𝚎</Button>}
          {!this.state.isLoading && !this.state.serverError && this.state.memes.length === 0 && !this.state.favsOnly && <Button variant="outlined" disabled>No Meme Found</Button>}
          {!this.state.isLoading && !this.state.serverError && this.state.memes.length === 0 && this.state.favsOnly && <Button variant="outlined" disabled>No Favorite Meme Found</Button>}
        </div>
        <ScrollUpButton EasingType="easeInOutCirc" ContainerClassName="hexagon" TransitionClassName="toggled">
          <div class="icon">⬢</div>
        </ScrollUpButton>
      </div>
    );
  }
}

export default App;
